import { useLayoutEffect, useState } from "react";

import SearchContent from "../components/search/SearchContent";
import ContentInitialState from "./content.state";
import { useCreateReducer } from "../hooks/useCreateReducer";
import { ContentContext } from "./context";
import useMicroApp from "../hooks/useMicroApp";
import Header from "../components/header/Header";
import useStorage from "../hooks/useStorage";
import { switchTheme, SearchHeaderStyle } from "../utils/switchTheme";

export default function Main() {
  const contentContext = useCreateReducer(ContentInitialState);
  const {
    state: { headerType, contentType, bgScreen },
    dispatch: contentDispatch,
  } = contentContext;

  const { setStorageItem, getStorageItem } = useStorage();
  const { IframeTemplate, iframeSrc, setIframeSrc } =
    useMicroApp(setStorageItem);

  const [headerView, setHeaderView] = useState(false);
  const [headerStyle, setHeaderStyle] = useState(SearchHeaderStyle);

  const handleContentType = (value) => {
    contentDispatch({ field: "contentType", value });
  };

  const handleBgScreen = (value) => {
    contentDispatch({ field: "bgScreen", value });
  };

  const handleUserInfo = (value) => {
    contentDispatch({ field: "userInfo", value });
  };

  useLayoutEffect(() => {
    if (getStorageItem("contentType") && getStorageItem("contentType") !== -1) {
      handleContentType(getStorageItem("contentType"));
    }
  }, [getStorageItem("contentType")]);

  useLayoutEffect(() => {
    if (headerType === "flex") {
      setHeaderView(true);
    } else {
      setHeaderView(false);
    }
  }, [headerType]);

  useLayoutEffect(() => {
    switchTheme(
      contentType,
      handleBgScreen,
      setHeaderStyle,
      setIframeSrc,
      setStorageItem,
      getStorageItem("path") === -1 || !getStorageItem("path")
        ? ""
        : getStorageItem("path"),
    );
  }, [contentType, getStorageItem("path")]);

  return (
    <ContentContext.Provider
      value={{
        ...contentContext,
        handleContentType,
        handleBgScreen,
        handleUserInfo,
      }}
    >
      <div
        className={`flex flex-col w-screen h-screen ${bgScreen} bg-repeat bg-center bg-cover`}
      >
        <div
          className={`flex flex-row w-full ${headerStyle} pl-[50px] pr-[40px] ${
            headerType === "hidden" ? "hidden" : ""
          }`}
        >
          <Header headerIsView={headerView} />
        </div>

        {/* content view */}
        <div
          className={`w-full h-full ${
            headerType === "flex" ? "pt-[60px]" : ""
          }`}
        >
          {iframeSrc === "" ? <SearchContent /> : <IframeTemplate />}
        </div>
      </div>
    </ContentContext.Provider>
  );
}
