import { ReactComponent as LANGSA_LOGO } from "../../assets/images/langsa-logo.svg";
import { ReactComponent as LANGSA_TEXT } from "../../assets/images/langsa-bada-text.svg";
import { ReactComponent as IconGlasses } from "../../assets/images/icons/icon-glasses.svg";
import { ReactComponent as IconClose } from "../../assets/images/icons/icon-close.svg";

import { useContext, useEffect, useState } from "react";
import useSession from "../../services/useSession";
import { ContentContext } from "../../pages/context";
import defineConfig from "../../config/defineConfig";
import LoadingSpinner from "../LoadingSpinner";
import useStorage from "../../hooks/useStorage";

function SearchContent() {
  const { removeStorageItem } = useStorage();
  const { handleContentType } = useContext(ContentContext);

  const { setSessionChatMsg } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [text, setText] = useState("");

  const handleSearch = (e) => {
    setText(e.target.value);
  };

  const handleSearchClear = () => {
    setText("");
  };

  const onEnterKeyPress = () => {
    if (window.event.keyCode === 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    if (text !== "") {
      removeStorageItem("path");
      setSessionChatMsg(text).then((setRes) => {
        console.log("setSessionChatMsg response >>>> ", setRes);
        if (setRes.code === 200) {
          console.log("text >>>> ", text);
          // 데모 수정
          if (defineConfig.isDemo) {
            window.location.href = process.env.REACT_APP_PLAYGROUND_ENDPOINT;
          } else {
            handleContentType(
              defineConfig.ContentRoute.playground.replace("/", ""),
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="flex flex-col h-full items-center justify-start space-y-[22px] pt-[12%]">
      <LANGSA_TEXT width={250} height={75} />
      <div className="text-[16px] leading-[20px] font-[500] text-[#FFFFFF]">
        {defineConfig.ContentList.contentType?.text ||
          defineConfig.ContentList.search.text}
      </div>
      <div className="flex flex-row w-[670px] items-center justify-between">
        <div className="relative flex-row items-center justify-end">
          <IconGlasses
            fill="#000"
            width={24}
            height={24}
            className="absolute bottom-[13px] right-[2%] cursor-pointer"
            onClick={onSearch}
          />
          <IconClose
            fill="#959595"
            width={15}
            height={15}
            className={`absolute bottom-[18px] right-[8%] cursor-pointer ${
              text !== "" ? "block" : "hidden"
            }`}
            onClick={handleSearchClear}
          />
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            className="w-[600px] h-[50px] rounded-[25px] indent-[18px]"
            value={text}
            onChange={handleSearch}
            onKeyUp={onEnterKeyPress}
          />
        </div>
        <LANGSA_LOGO width={50} height={50} />
      </div>
    </div>
  );
}

export default SearchContent;
