import defineConfig from "../config/defineConfig";
import ContentInitialState from "../pages/content.state";

const SearchHeaderStyle = "py-[30px]";
const KmsHeaderStyle = "pt-[10px] pb-[9px] border-b-[1px] border-b-[#EAEFFF]";
const OtherHeaderStyle = "py-[10px]";

const switchTheme = (
  type,
  handleBgScreen,
  setHeaderStyle,
  setIframeSrc,
  setStorageItem,
  path = "",
) => {
  switch (type) {
    case "playground":
      handleBgScreen("bg-white");
      setHeaderStyle(OtherHeaderStyle);
      setIframeSrc(`${defineConfig.ContentList.playground.url}${path}`);
      setStorageItem("url", defineConfig.ContentList.playground.url);
      break;
    case "kms":
      handleBgScreen("bg-white");
      setHeaderStyle(KmsHeaderStyle);
      setIframeSrc(`${defineConfig.ContentList.kms.url}${path}`);
      setStorageItem("url", defineConfig.ContentList.kms.url);
      break;
    case "builder":
      handleBgScreen("bg-white");
      setHeaderStyle(OtherHeaderStyle);
      setIframeSrc(`${defineConfig.ContentList.builder.url}${path}`);
      setStorageItem("url", defineConfig.ContentList.builder.url);
      break;
    default:
      // 데모 수정
      handleBgScreen(ContentInitialState.bgScreen);
      setHeaderStyle(SearchHeaderStyle);
      setIframeSrc(defineConfig.ContentList.search.url);
      break;
  }
};

export { switchTheme, SearchHeaderStyle, KmsHeaderStyle, OtherHeaderStyle };
